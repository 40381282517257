<template>
  <header class="header">
    <div class="container header__container">
      <a class="header__logo logo" href="/">
        <img src="@/assets/img/logo.svg" alt="">
      </a>
      <div class="header__contacts">
        <a class="header__phone" href="tel:88006008776">
          8 800 600-87-76
        </a>
        <a href="#" class="header__recall">
          Обратный звонок
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
}
</script>

<style scoped>
</style>

<template>
  <TheHeader/>
  <main class="main">
    <TheQuiz/>
  </main>
  <TheFooter/>
</template>

<script>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import TheQuiz from './components/TheQuiz.vue'

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    TheQuiz,
  }
}
</script>

<style>
</style>

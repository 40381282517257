<template>
  <footer class="footer">
    
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
}
</script>

<style scoped>
</style>

<template>
  <secton class="quiz">
		<div v-if=" step == 0 " class="quiz__item quiz__item_first">
			<div class="container quiz__container quiz__container_center">
				<div class="quiz__content">
					<h1 class="title-main quiz__main-title">
						Подобрать квартиру в&nbsp;новостройке онлайн
					</h1>
					<p class="quiz__text">
						Ответьте на несколько вопросов, и&nbsp;мы&nbsp;бесплатно отправим список ЖК с&nbsp;выгодными акциями в январе
					</p>
					<div class="quiz__btn-wrapper">
						<button class="btn-default quiz__btn"
							@click="step = 1">
							Пройти тест
						</button>
						<div class="quiz__btn-info">
							Это займет 1 миниту
						</div>
					</div>
					<div class="quiz__numbers">
						<div class="quiz__numbers-item">
							<div class="quiz__numbers-item-value">
								25 000+ 
							</div>
							<div class="quiz__numbers-item-desc">
								квартир в Москве<br>
								и регионах
							</div>
						</div>
						<div class="quiz__numbers-item">
							<div class="quiz__numbers-item-value">
								10+
							</div>
							<div class="quiz__numbers-item-desc">
								застройщиков
							</div>
						</div>
						<div class="quiz__numbers-item">
							<div class="quiz__numbers-item-value">
								12
							</div>
							<div class="quiz__numbers-item-desc">
								регионов<br>
								присутствия
							</div>
						</div>
						<div class="quiz__numbers-item">
							<div class="quiz__numbers-item-value">
								19
							</div>
							<div class="quiz__numbers-item-desc">
								лет на рынке
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 
		<div v-if=" step > 0 " class="quiz__item quiz__item_step">
			<div class="container quiz__container">
				<div class="quiz__steps" v-if="step < 7">
					<template
						v-for="(item, index) in steps"
						:key = index>
						<div class="quiz__step"
							:class="stepClasses(item, index)">
							{{ index + 1  }}
						</div>
					</template>
				</div>
				<template
					v-for="(item, key) in steps"
					:key = key>
					<div class="quiz__step-content"
						:class = "`quiz__step-content_${item.name}`"
						v-show="key + 1 == step">
						<h2 class="title-default quiz__title">
							{{ item.label }}
						</h2>
						<div class="quiz__options">
							<div class="quiz__options-group" v-for="(element, index) in item.inputGroups" :key="index">
								<template v-if="element.type == 'checkbox'">
									<label class="custom-checkbox" v-for="(input, index) in element.items" :key="index">
										<input 
											type="checkbox"
											:name="element.name" 
											:value="input"
											@change="check($event, element, input)">
										<span class="custom-checkbox__name">
											{{ input.value }}
										</span>
									</label>
								</template>
								<template v-if="element.type == 'radio'">
									<label class="custom-radio" v-for="(input, index) in element.items" :key="index">
										<input 
											type="radio" 
											:name="element.name" 
											:value="input"
											@change="check($event, element)">
										<span class="custom-radio__name">
											{{ input }}
										</span>
									</label>
								</template>
							</div>
						</div>
					</div>
				</template>
				<div class="quiz__step-content quiz__step-content_contacts"
					v-show="step == 7">
					<img class="quiz__image-check" src="@/assets/img/check.svg" alt="">
					<h2 class="title-default quiz__title">
						{{ contacts.label }}
					</h2>
					<div class="quiz__options">
						<div class="quiz__options-group" v-for="(element, index) in contacts.inputGroups" :key="index">
							<label class="quiz__input custom-input">
								<input 
									type="input" 
									:name="element.name" 
									:placeholder="element.placeholder" 
									v-mask="element.mask"
									v-model="element.value"
									@input="validate()">
							</label>
						</div>
					</div>
				</div>
				<div class="quiz__btns-wrapper">
					<button 
						class="btn-back quiz__btn"
						type="button"
						v-if="step > 1"
						@click="step-=1">
						Назад
					</button>
					<button 
						class="btn-default quiz__btn"
						:class="steps[step - 1].filled?'':'quiz__btn_disabled'"
						type="button"
						v-if="step < 7"
						@click="nextStep()">
						Следующий вопрос
					</button>
					<button 
						class="btn-default quiz__btn"
						v-if="step == 7"
						:class="contacts.valid?'':'quiz__btn_disabled'"
						@click="submit()">
						Отправить
					</button>
				</div>
			</div>
		</div>
		<div v-if="success" class="quiz__success">
			<div class="quiz__success-overlay" @click="reset()"></div>
			<div class="quiz__success-content">
				<button class="quiz__success-close" @click="reset()">close</button>
				<h2 class="quiz__success-title">
					Ваша заявка успешно отправлена!
				</h2>
				<div class="quiz__success-line"></div>
				<p class="quiz__success-text">
					Менеджер свяжется с&nbsp;вами и&nbsp;расскажет какие варианты мы&nbsp;подобрали для вас
				</p>
			</div>
		</div>
  </secton>
</template>

<script>

// В шагах можно делать группы из полей ввода
// В финальном шаге можно только инпуты

export default {
  name: 'TheQuiz',
	data: () => ({
		steps: [
			{
				name: 'place',
				label: "Где присматриваете новостройку?",
				inputGroups: [
					{
						name: 'place',
						value: '',
						items: [
							{
								value: "В Москве и\xa0Московской области",
								checked: false
							},
							{value: 
								"В Санкт-Петербурге и\xa0Ленинградской области",
								checked: false
							},
							{
								value: "В Сочи или\xa0Краснодаре",
								checked: false
							}  
						],
						type: "checkbox",
						required: true,
					},
				],
				filled: false
			},
			{
				name: 'room',
				label: "Сколько комнат должно быть в\xa0квартире?",
				inputGroups: [
					{
						name: 'room',
						value: '',
						items: ["Студия / 1-комнатная", "2-комнатная", "3 или более комнат"],
						type: "radio",
						required: true
					},
				],
				filled: false
			},
			{
				name: 'metro',
				label: "Насколько важна близость до\xa0станции метро?",
				inputGroups: [
					{
						name: 'metro',
						value: '',
						items: ["Хочу ходить пешком", "Готов ехать на\xa0автобусе", "В\xa0моём городе нет метро"],
						type: "radio",
						required: true
					}
				],
				filled: false
			},
			{
				name: 'wait',
				label: "Хотите заехать сразу или готовы подождать сдачу дома?",
				inputGroups: [
					{
						name: 'wait',
						value: '',
						items: ["Рассматриваю только готовые квартиры", "Готов подождать до 1 года", "Не имеет значения"],
						type: "radio",
						required: true
					}
				],
				filled: false
			},
			{
				name: 'repair',
				label: "Интересуют дома с\xa0отделкой?",
				inputGroups: [
					{
						name: 'repair',
						value: '',
						items: ["С\xa0отделкой", "Без отделки", "Не имеет значения"],
						type: "radio",
						required: true
					}
				],
				filled: false
			},
			{
				name: 'budget',
				label: "На какой бюджет рассчитываете?",
				inputGroups: [
					{
						name: 'budget',
						value: '',
						items: ["До 12 млн руб.", "До 20 млн руб.", "Более 20 млн. руб"],
						type: "radio",
						required: true
					}
				],
				filled: false
			},
		],
		contacts: {
			name: 'contacts',
			label: "Для вас найдено 3\xa0новостройки. Оставьте свои контакты, чтобы увидеть все результаты",
			valid: false,
			inputGroups: [
				{
					name: 'name',
					value: '',
					placeholder: "Ваше имя",
					type: "input",
					error: false,
					required: true,
					valid: false
				},
				{
					name: 'phone',
					value: '',
					placeholder: "Номер телефона",
					type: "input",
					error: false,
					mask: "+7 ### ###-##-##",
					required: true,
					requiredRule: "phone",
					valid: false
				}
			]
		},
		step: 0,
		success: false
	}),
	methods: {
		reset() {
			this.success = false;
			// раскоментить если нужен сброс формы
			// this.step = 0;
			// Object.assign(this.$data, this.$options.data.apply(this));
		},
		nextStep() {
			if (this.steps[this.step - 1].filled) {
				this.step++;
			}
		},
		submit() {
			if (this.contacts.valid) {
				let _this = this;
				let sendData = {};

				let curEl = this.steps;
				let contacts = this.contacts;
				
				for (let steps of curEl) {
					for (let item of steps.inputGroups) {
						sendData[item.name] = item.value;
					}
				}
				
				for (let input of contacts.inputGroups) {
					sendData[input.name] = input.value;
				}

				console.log(sendData);

				_this.success = true;


				/*
				let xhr = new XMLHttpRequest;
				xhr.open('POST', '/', true);
				xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
				xhr.send(JSON.stringify(sendData));
				
				xhr.onreadystatechange = function() {
					if (xhr.readyState != 4) return;

					// console.log('Send')

					if (xhr.status != 200) {
						// обработать ошибку
						console.log(xhr.status + ': ' + xhr.statusText);
					} else {
						// вывести результат
						// console.log(xhr.responseText);
						_this.success = true;
						// try {
						// 	ym(13262362,'reachGoal','KASKO_LEAD_SENT');
						// 	console.log('YM KASKO_LEAD_SENT');
						// } catch(e) {
						// 	console.log('YM fail');
						// }
					}
				}
				*/
			}
		},
		validate() {
			this.steps.forEach(item => {
				item.filled = true;
				item.inputGroups.forEach(el => {
					if (el.value === '') {
						item.filled = false;
					}
				})
			})

			this.contacts.valid = true;
			this.contacts.inputGroups.forEach(el => {
				el.valid = true;

				switch (el.requiredRule) {
					case 'phone':
						if (el.value.length < 16) {
							el.valid = false;
							this.contacts.valid = false;
						}
						break;
				
					default:
						if (el.value == '') {
							el.valid = false;
							this.contacts.valid = false;
						}
						break;
				}
			})

		},
		check($e, el, input = false) {
			if (input) {
				input.checked = $e.target.checked;

				el.value = '';

				el.items.forEach(item => {
					if (item.checked) {
						el.value += ', ' + item.value; 
					}
				})

				el.value = el.value.replace(', ','');
			}
			else {
				el.value = $e.target.checked?$e.target.value:'';
			}
			this.validate();
		},
		stepClasses(item, index) {
			return {
				'quiz__step_active' : (this.step === index + 1), 
				'quiz__step_success' : item.filled, 
			}
		}
	}
}
</script>

<style scoped>
</style>
